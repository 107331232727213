.SuggestionPopup {
  display: flex;
  position: fixed;
  top: 25%;
  left: 25%;
  width: 50vw;
  height: 50vh;
  border: 4px solid #404757;
  border-radius: 20px;
  background-color: #1d1f24;
  flex-direction: column;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 90%);
}
.SuggestionPopup h1 {
  flex-grow: 1;
}
.SuggestionPopup h2 {
  flex-grow: 1;
}

.SuggestionPopup-button {
  background-color: #FFA500;
  flex-grow: 1;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: x-large;
}

.SuggestionPopup-button:hover {
  cursor: pointer;
}
