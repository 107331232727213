.SuggestionBox {
  display: flex;
  flex-direction: col;
  background-color: #1d1f24;
  border: 4px solid #404757;
  border-radius: 25px;
  position: fixed;
  bottom: 0;
  left: 20%;
}

.SuggestionBox-hide-button-hidden {
  background-color: #FFA500;
  position: fixed;
  bottom: 0;
  left: 20%;
  transition: 0.4;
  font-size: x-large;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.SuggestionBox-hide-button-hidden:hover {
  cursor: pointer;
}

.SuggestionBox-hide-button-visible {
  background-color: #FFA500;
  flex-grow: 1;
  border-top-right-radius: 20px;
  font-size: x-large;
  border-color: transparent;
  margin-bottom: 1px;
  margin-right: 2px;
}

.SuggestionBox-hide-button-visible:hover {
  cursor: pointer;
}

.SuggestionBox-submit-button {
  background-color: #FFA500;
  flex-grow: 1;
  font-size: x-large;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.SuggestionBox-submit-button:hover {
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 1px;
}

.SuggestionBox-content-contracted {
  transition: width 0.4s, height 0.4s, box-shadow 0.2s;
  flex-grow: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 1px;
  width: 1px;
}

.SuggestionBox-content-expanded {
  transition: width 0.4s, height 0.4s, box-shadow 0.2s;
  flex-grow: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60vw;
  height: 25vh;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 90%);
  border-radius: 20px;
}

.SuggestionBox-title {
  flex-grow: 1;
  font-size: x-large;
  font-family: sans-serif;
  margin-right: 1px;
  margin-left: 1px;
  resize: none;
}

.SuggestionBox-details {
  flex-grow: 9;
  font-size: x-large;
  font-family: sans-serif;
  margin-right: 1px;
  margin-left: 1px;
}

.SuggestionBox-button-box {
  flex-grow: 1;
  display: flex;
}

.SuggestionBox-button-spacer {
  flex-grow: 23;
}

.SuggestionBox-one-space {
  flex-grow: 1;
}

@media ( width <= 30em ) {
  .SuggestionBox-hide-button-hidden {
    font-size: medium;
  }

  .SuggestionBox-hide-button-visible {
    font-size: medium;
  }

  .SuggestionBox-content-expanded {
    font-size: medium;
  }

  .SuggestionBox-submit-button {
    font-size: medium;
  }

  .SuggestionBox-title {
    font-size: medium;
  }

  .SuggestionBox-details {
    font-size: medium;
  }

  .SuggestionBox {
    font-size: medium;
    width: 80vw;
    left: 10%;
  }
}

@media ( height < 40em ) {
  .SuggestionBox-submit-button {
    margin-bottom: 2.5em;
  }

  .SuggestionBox-title {
    min-height: 1em;
  }

  .SuggestionBox-details {
    min-height: 1em;
  }
}
