.Episode {
  display: flex;
  flex-direction: column;
  background-color: #1d1f24;
  max-width: 90%;
  border: 4px solid #404757;
  border-radius: 20px;
}

.Episode-non-buttons {
  display: flex;
  flex-direction: row;
}

.Episode-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Episode-number-and-title {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: flex-start;
}

.Episode-number {
  flex-grow: 1;
}

.Episode-download-icon {
  max-width: 100%;
  max-height: 100%;
}

.Episode-title-hidden {
  flex-grow: 1;
  color: transparent;
  text-shadow: 0 0 30px rgba(255, 255, 255, 0.75);
  transition: 0.4s;
}

.Episode-title-hidden:hover {
  text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  cursor: pointer;
}

.Episode-title-shown {
  flex-grow: 1;
  transition: 0.4s;
  cursor: pointer;
}

.Episode-title-buffer {
  flex-grow: 11;
}

.Episode-image {
  flex-grow: 5;
  height: 40vmin;
}

.Episode-image img {
  max-width: 100%;
  max-height: 100%;
}

.Episode-right {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-width: 40%;
}

.Episode-buttons {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  align-self: stretch;
  justify-content: space-around;
  height: 50px;
  margin-top: 2px;
}

.Episode-buttons button:hover {
  cursor: pointer;
}

.Episode-buttons button {
  width: 10vw;
}

.Episode-buttons input {
  width: 20vw;
}

.Episode-download-button {
  background-color: #FFA500;
}

.Episode-download-button:hover {
  cursor: pointer;
}

.Episode-right-buffer {
  flex-grow: 10;
}

.Episode-subs-button {
  background-color: #FFA500;
}

.Episode-description {
  margin-left: 20px;
  margin-right: 20px;
}

