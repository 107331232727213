@media (prefers-reduced-motion: no-preference) {
  .SpinningLogo {
    animation: SpinningLogo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
}

@keyframes SpinningLogo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
