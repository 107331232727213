.Arthouse {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 10%;
}

.Arthouse h1 {
  font-size: 1.5em;
}

.Arthouse h4 {
  text-align: left;
}

.Arthouse-season-picker {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  max-width: 90%;
  border-radius: 5px;
}

.Arthouse-season-picker p {
  transition: 0.3s;
  opacity: 0.6;
  margin: 1px;
}

.Arthouse-season-picker p:hover {
  opacity: 1.0;
  cursor: pointer;
}

@media ( width <= 30em ) {
  .Arthouse-season-picker p {
    font-size: calc(6px + 2vmin);
  }
}

.Arthouse-season {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

